import Vue from "vue";
import VueRouter from "vue-router";
import localStorageUtils from "@/utils/localStorageUtils";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: () => import("@/components/LayoutView.vue"),
      children: [
        // {
        //   path: "/",
        //   name: "控制台",
        //   icon: "el-icon-s-cooperation",
        //   component: () => import("@/views/HomeView.vue"),
        // },
        {
          path: "/student",
          name: "报修管理",
          icon: "el-icon-s-tools",
          component: () => import("@/components/MainView"),
          children: [
            {
              path: "studentList",
              name: "申请报修列表",
              component: () => import("@/views/Students/StudentList.vue"),
            },
            // {
            //   path: "infomanage",
            //   name: "信息管理",
            //   component: () => import("@/views/Students/InfoManage.vue"),
            // },
            // {
            //   path: "homework",
            //   name: "作业列表",
            //   component: () => import("@/views/Students/HomeWork.vue"),
            // },
          ],
        },
        // {
        //   path: "/graphs",
        //   name: "数据分析",
        //   icon: "el-icon-s-marketing",
        //   component: () => import("@/components/MainView"),
        //   children: [
        //     {
        //       path: "overview",
        //       name: "数据概览",
        //       component: () => import("@/views/Graphs/OverView.vue"),
        //     },
        //     {
        //       path: "mapview",
        //       name: "地图概览",
        //       component: () => import("@/views/Graphs/MapView.vue"),
        //     },
        //     {
        //       path: "travelmap",
        //       name: "旅游地图",
        //       component: () => import("@/views/Graphs/TravelMap.vue"),
        //     },
        //     {
        //       path: "scoremap",
        //       name: "分数地图",
        //       component: () => import("@/views/Graphs/ScoreMap.vue"),
        //     },
        //   ],
        // },
        // {
        //   path: "/users",
        //   name: "用户中心",
        //   icon: "el-icon-s-custom",
        //   component: () => import("@/views/Users"),
        // },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("@/views/NotFound.vue"),
    },
  ],
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  const token = localStorageUtils.get("token");
  if (token) {
    // 如果有token，并且试图访问登录页，则重定向到首页
    if (to.path === "/login") return next("/");
    // 其他情况下直接放行
    return next();
  }
  // 如果没有token，并且试图访问非登录页，则重定向到登录页
  if (to.path !== "/login") return next("/login");
  // 其他情况下直接放行
  return next();
});

export default router;
